// import ResizeObserver from 'resize-observer-polyfill';
import {
    html,
    body,
    ENV,
    CSS_CLASS,
    EVENTS,
    CUSTOM_EVENTS,
    FONT
}                              from './utils/environment';
import { getWindowDimensions } from './utils/window';
import { getMousePos }         from './utils/mouse';
import { debounce, throttle }  from './utils/tickers';
import debug                   from './utils/debug';
import { rafTimeout, calcFPS }             from './utils/raf';
// import { isFontLoadingAPIAvailable, loadFonts } from './utils/fonts';

import polyfill                from './polyfill';
import globals                 from './globals';
import { Navigation }          from './modules/Navigation';
// import { LazyScript }          from './modules/LazyScript';

import lazySizes               from 'lazysizes';
import Lenis                   from 'lenis';
import { inView }              from 'motion';


// Globals
polyfill();

// lazySizesConfig.loadMode (default: 2): The loadMode can be used to constrain the allowed loading mode. Possible values are 0 = don't load anything, 1 = only load visible elements, 2 = load also very near view elements (expand option) and 3 = load also not so near view elements (expand * expFactor option). This value is automatically set to 3 after onload. Change this value to 1 if you (also) optimize for the onload event or change it to 3 if your onload event is already heavily delayed.
lazySizes.cfg.loadMode = 1;

// TODO: class TextSplitter


////////////////
// App
////////////////

class App {

    constructor() {
        debug('init the app');

        this.pageIsLoaded = false;
        this.page = '';
        this.components = [];
        this.pageComponents = [];
        this.lenis = null;
        this.DOM = {
            header: null,
        };
        this.bounds = {
            width: 0,
            height: 0,
            headerH: 0,
        };
        this.scroll = {
            target: 0,
            progress: 0,
            treshold: 100,
            direction: false,
        };

        // Get DOM
        this.DOM.header = document.getElementById('ui-top');

        // Vars
        const windowDim = getWindowDimensions();
        this.bounds.width = windowDim.width;
        this.bounds.height = windowDim.height;
        this.bounds.headerH = this.DOM.header.offsetHeight;
        // this.scroll.treshold = ENV.IS_MOBILE ? this.bounds.width / 10 : this.bounds.width / 3;

        if (!ENV.SUPPORT_CSSVAR) html.classList.add('no-css-var');
        if (!ENV.SUPPORT_MIXBLENDMODE) html.classList.add('no-mix-blend-mode');
        html.classList.add(ENV.IS_REDUCED_MOTION ? 'is-reduced-motion' : 'no-reduced-motion');
        html.classList.add(ENV.IS_TOUCH ? 'is-touch' : 'no-touch');
        html.classList.add(ENV.IS_MOBILE ? 'is-mobile' : 'is-desktop');

        this.bindAll();

        this.lenis = this.createLenis();
        window.lenis = this.lenis;

        this.init();
        this.events();
        this.copyright();
    }

    init() {
        this.page = html.dataset.page;

        this.components = this.createComponents();

        this.initPageTransition();

        // Load async components specific to the page
        this.loadComponents();

        // // Get menu links
        // this.links = [
        //   ...document.querySelectorAll('#primary-menu a'),
        //   // ...document.querySelectorAll('.site-header .header-widget a')
        //   ...document.querySelectorAll('#full-menu a')
        // ];

        // Scroll to anchors
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                this.lenis.scrollTo(this.getAttribute('href'), {
                    // offset: this.bounds.headerH,
                    duration: 1.5,
                    // onComplete: () => {
                    //   console.log('end of lenis scrollTo');
                    // }
                });
            });
        });

        // Page is ready
        this.onPageReady().then(() => {
            // This timeout = animation out of the pageloader
            setTimeout(() => {
                this.onPageLoaded().then(() => {
                    this.pageIsLoaded = true;
                    this.lenis.resize();

                    // onPageLoaded();
                });
            }, 650);
        });
    }

    createComponents() {
        const _this = this;

        // Load global components
        const components = [];

        components.push(new Navigation({ toggle: document.getElementById('js-nav-toggle') }));

        if (!ENV.IS_REDUCED_MOTION) {

            if (!ENV.IS_TOUCH) {

                // Init Cursor
                // => always loaded if not in mobile
                // const cursorModule = await import('./modules/Cursor');
                // gridHelper = gridHelperModule?.gridHelper;

                import('./modules/Cursor').then(function (module) {
                    // debug('add component Cursor', module.Cursor);
                    _this.pageComponents.push(new module.Cursor());
                });

            }

        }

        // debug('registered initial components', components);
        return components;
    }

    loadComponents() {
        const _this = this;

        if (!ENV.IS_REDUCED_MOTION) {

            // Init Lightbox only if we have elements in the page
            // => load only when first elem enter viewport
            // if (
            //   document.querySelectorAll('*[data-zoom]').length ||
            //   document.querySelectorAll('.lightbox').length
            // ) {}
            const lightboxElems = [...document.querySelectorAll('*[data-zoom],.' + CSS_CLASS.LIGHTBOX)];
            let isInitLightbox = false;
            if (lightboxElems) {
                const stopLightboxView = inView(lightboxElems, () => {
                    stopLightboxView();

                    if (!isInitLightbox) {
                        isInitLightbox = true;
                        import('./modules/Lightbox').then(function (module) {
                            // debug('lightbox script is loaded', module.Lightbox);
                            _this.pageComponents.push(new module.Lightbox());
                        }).catch((err) => {
                            console.warn('ERROR loading Lightbox', err);
                            html.classList.add('no-lightbox');
                        });
                    }
                });
            }

            // Init RevealOnScroll only if we have elements in the page
            const firstROSElem = document.querySelector('[data-reveal]');
            if (firstROSElem) {
                import('./modules/Reveal').then(function (module) {
                    // debug('add component Reveal', module.Reveal);
                    const Reveal = new module.Reveal(_this.bounds);
                    _this.pageComponents.push(Reveal);

                    // let nIntervId;
                    // nIntervId = setInterval(() => {
                    //   if (_this.pageIsLoaded) {
                    //     debug('page is Loaded');
                    //     clearInterval(nIntervId);
                    //     // Reveal.init(window_bounds);
                    //   }
                    // }, 20);

                }).catch((err) => {
                    console.warn('ERROR loading Reveal', err);
                    html.classList.add('no-reveal');
                });
            }

            // Init ScrollItem only if we have elements in the page
            const firstScrollItem = document.querySelector('[data-scroll]');
            if (firstScrollItem) {
                import('./modules/ScrollItem').then(function (module) {
                    // debug('add component ScrollItem', module.ScrollItem);
                    _this.pageComponents.push(new module.ScrollItem());
                }).catch((err) => {
                    console.warn('ERROR loading ScrollItem', err);
                    html.classList.add('no-scrollitem');
                });
            }

            // Init Marquee Item only if we have elements in the page
            // => load only when first elem enter viewport
            const marqueeItems = [...document.querySelectorAll('.marquee')];
            let isInitMarquee = false;
            if (marqueeItems) {
                const stopMarqueeView = inView(marqueeItems, () => {
                    stopMarqueeView();

                    if (!isInitMarquee) {
                        isInitMarquee = true;

                        import('./modules/Marquee').then(function (module) {
                        // debug('add component Marquee', module.Marquee);
                            _this.pageComponents.push(new module.Marquee(marqueeItems));
                        }).catch((err) => {
                            console.warn('ERROR loading Marquee', err);
                            html.classList.add('no-marquee');
                        });
                    }
                });
            }

            // Init Tabs Item only if we have elements in the page
            // => load only when first elem enter viewport
            const tabsItems = [...document.querySelectorAll('.tabs-nav')];
            let isInitTabs = false;
            if (tabsItems) {
                const stopTabsView = inView(tabsItems, () => {
                    stopTabsView();

                    if (!isInitTabs) {
                        isInitTabs = true;

                        import('./modules/Tabs').then(function (module) {
                            // debug('add component Tabs', module.Tabs);
                            _this.pageComponents.push(new module.Tabs(tabsItems, _this.bounds));
                        }).catch((err) => {
                            console.warn('ERROR loading Tabs', err);
                            html.classList.add('no-tabs');
                        });
                    }
                });
            }

            if (!ENV.IS_TOUCH) {

                // Init WebGL Card only if we have elements in the page
                // => load only when first elem enter viewport
                const webglCards = [...document.querySelectorAll('[data-webgl-card]')];
                if (webglCards.length) {
                    import('./modules/WebGLCard').then(function (module) {
                        // debug('add component WebGLCard', module);
                        _this.pageComponents.push(new module.WebGLCard(webglCards));
                    }).catch((err) => {
                        console.warn('ERROR loading WebGLCard', err);
                        html.classList.add('no-webgl');
                    });
                }

                // Init Mouse Slider only if we have elements in the page
                // => load only when first elem enter viewport
                const mouseSliders = [...document.querySelectorAll('.mouse-slider-wrapper')];
                let isInitMouseSliders = false;
                if (mouseSliders) {
                    const stopMouseSliderView = inView(mouseSliders, () => {
                        stopMouseSliderView();

                        import('./modules/MouseSlider').then(function (module) {
                            // debug('add component MouseSlider', module);
                            if (!isInitMouseSliders) {
                                isInitMouseSliders = true;
                                _this.pageComponents.push(new module.MouseSlider(mouseSliders, _this.bounds));
                            }
                        }).catch((err) => {
                            console.warn('ERROR loading MouseSlider', err);
                            html.classList.add('no-mouse-slider');
                        });
                    });
                }
            }
        }
        else {

            // debug('reduce motion is active : no reveal, webgl-card, scrollItem, Cursor');

            html.classList.add('reduced-motion');
            html.classList.add('no-reveal');
            html.classList.add('no-scrollitem');
            html.classList.add('no-webgl');

        }
    }

    createLenis() {
        // Set up Lenis scroll
        const lenis = new Lenis({
            // wrapper: window, // The element that will be used as the scroll container
            // content: document.documentElement, // The element that contains the content that will be scrolled, usually `wrapper`'s direct child
            // wheelEventsTarget: wrapper, // The element that will listen to `wheel` events
            lerp: 0.08, // Default: 0.1 - Linear interpolation (lerp) intensity (between 0 and 1)
            // duration: 1.2, // The duration of scroll animation (in seconds). Useless if lerp defined
            // easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // he easing function to use for the scroll animation, our default is custom but you can pick one from Easings.net. Useless if lerp defined, https://easings.net/en
            // orientation: 'vertical', // The orientation of the scrolling. Can be `vertical` or `horizontal`
            // gestureOrientation: 'vertical', // The orientation of the gestures. Can be `vertical`, `horizontal` or `both`
            smoothWheel: true, // Whether or not to enable smooth scrolling for mouse wheel events.
            // smoothTouch: false, // Whether or not to enable smooth scrolling for touch events.
            // syncTouch: true, // Mimic touch device scroll while allowing scroll sync (can be unstable on iOS<16)
            // syncTouchLerp: 0.1, // Lerp applied during `syncTouch` inertia
            // touchInertiaMultiplier: 35, // Manage the the strength of `syncTouch` inertia
            // wheelMultiplier: 1, // The multiplier to use for mouse wheel events
            // touchMultiplier: 2, // The multiplier to use for touch events
            // normalizeWheel: false, // Normalize wheel inputs across browsers (not reliable atm)
            // infinite: true, // Enable infinite scrolling!
            // autoResize: true, // Resize instance automatically based on ResizeObserver. If false you must resize manually using .resize()
        });

        // Scroll to top
        document.getElementById('btn-top').addEventListener('click', (e) => {
            e.preventDefault();
            lenis.scrollTo('top', {
                duration: 1.5,
            });
        });

        return lenis;
    }

    events() {
        const { MOVE, UP, DOWN, RESIZE, SCROLL, WHEEL } = EVENTS;

        // RAF Event
        // gsap.ticker.add(this.handleRAF);
        // raf.on(this.handleRAF);
        requestAnimationFrame(this.handleRAF);


        // Resize event
        window.addEventListener(RESIZE, debounce(this.handleResize, 200), false);

        // const resizeEndEvent = new CustomEvent(CUSTOM_EVENTS.RESIZE_END);
        // window.addEventListener(RESIZE, debounce(() => {
        //   window.dispatchEvent(resizeEndEvent)
        // }, 200, false));


        // Cursor events
        // window.addEventListener(DOWN, this.handleMouseDown);
        window.addEventListener(MOVE, throttle(this.handleMouseMove, 15), false);
        // window.addEventListener(UP, this.handleMouseUp);

        // // Wheel events
        // if (!ENV.IS_TOUCH) {
        //     window.addEventListener(WHEEL, throttle(this.handleWheel, 10));
        //     window.addEventListener(WHEEL, debounce(this.handleWheelEnd, 100), false);
        // }

        // Scroll Events
        this.lenis.on(SCROLL, throttle(this.handleScroll, 10));
        // this.lenis.on(SCROLL, debounce(this.handleScrollEnd, 100), false);
    }


    // Init Page transition
    // = click on link
    // => anim the content to fade out / transform
    initPageTransition() {
        // this.load = new modularLoad({
        //   enterDelay: 600, // Minimum delay before the new container enters.
        //   exitDelay: 50, // Delay before the old container exists after the new enters.
        //   loadedDelay: 0, // Delay before adding the loaded class. For example, to wait for your JS DOM updates.
        //   // transitions: {
        //   //   customTransition: {
        //   //     enterDelay: 450
        //   //   }
        //   // }
        // });

        // // On link click.
        // // change page
        // this.load.on('loading', (transition, oldContainer) => {
        //   debug('--------------------');
        //   debug('transition start from Load.js', oldContainer, transition);

        //   html.classList.remove(CSS_CLASS.READY);
        //   html.classList.remove(CSS_CLASS.LOADED);
        //   html.classList.add(CSS_CLASS.LEAVING);
        //   html.classList.add(CSS_CLASS.LOADING);

        //   this.onPageOut().then(() => {
        //     this.pageComponents = [];
        //   });
        // });

        // // On new container enter.
        // this.load.on('loaded', (transition, oldContainer, newContainer) => {
        //   debug('--------------------');
        //   debug('transition loaded from Load.js', oldContainer, newContainer);
        //   html.classList.remove(CSS_CLASS.LEAVING);
        //   html.classList.remove(CSS_CLASS.HAS_SCROLL);
        //   body.className = newContainer.dataset.bodyClass;
        //   window.scrollTo(0, 0);
        // });

        // // On old container exit.
        // this.load.on('ready', (transition, newContainer) => {
        //   debug('--------------------');
        //   debug('transition ready from Load.js', newContainer);

        //   // Analytics (from Highway doc)
        //   if (typeof gtag !== 'undefined') {
        //     debug('send statics by Google Analytics(gtag.js)', location);
        //     // send statics by Google Analytics(gtag.js)
        //     if (typeof THEME_GA_CODE !== 'undefined') {
        //       gtag('config', GA_CODE, {
        //         'page_path': location.pathname,
        //         'page_title': document.title,
        //         'page_location': location.href,
        //         'use_amp_client_id': true
        //       });
        //     }
        //   }
        //   else if (typeof ga === 'function') {
        //     debug('send statics by Google Analytics(analytics.js) or Google Tag Manager', location);
        //     // send statics by Google Analytics(analytics.js) or Google Tag Manager
        //     var trackers = ga.getAll();
        //     trackers.forEach(function (tracker) {
        //       // eslint-disable-next-line
        //       ga(tracker.get('name') + '.send', 'pageview', location.pathname, { 'useAmpClientId': true });
        //     });
        //   }

        //   // Nav
        //   this.links.forEach(l => {
        //     // debug(l.parentNode);
        //     l.parentNode.classList.remove('current-menu-item');
        //     l.parentNode.classList.remove('current_page_item');

        //     // if (l.href === location.href && trigger === 'popstate') {
        //     if (l.href === location.href) {
        //       l.parentNode.classList.add('current-menu-item');
        //       l.parentNode.classList.add('current_page_item');
        //     }
        //   });
        //   // if (typeof trigger === 'object') trigger.parentNode.classList.add('current-menu-item');
        //   // if (typeof trigger === 'object') trigger.parentNode.classList.add('current_page_item');

        //   this.init();

        //   // setTimeout(() => {

        //   //   // Pageloader is out
        //   //   // => init the app
        //   //   // this.call('update', newContainer, 'app');
        //   //   // this.call('initAncreLink', 'Scroll');
        //   //   // this.call('addHoverEvents', 'Cursor');
        //   //   onPageLoaded();

        //   // }, 650);
        // });


        let current_url = window.location.href;
        if (current_url.indexOf('#') !== -1) {
            current_url = current_url.substring( 0, current_url.indexOf('#') );
        }
        // debug('current url is ' + current_url);

        [...document.querySelectorAll('a')].forEach((a) => {

            if (
                a.classList.contains('no-transition') ||
                a.classList.contains('no-ajax') ||
                a.classList.contains('parvbox')
            ) {
            } else {
                const url = a.getAttribute('href');
                let url_withtout_hash = url;
                let url_has_hash = url.indexOf('#') !== -1;
                if (url_has_hash) {
                    url_withtout_hash = url.substring( 0, url.indexOf('#') );
                }

                if (
                    url
                    && url.indexOf(site_url) === 0
                    && url.indexOf('.zip') === -1
                ) {
                    // debug('link url is', url, url_withtout_hash);
                    // debug(url_withtout_hash === current_url ? 'same url' : 'different url');

                    if (
                        !url_has_hash
                        ||
                        (
                            url_has_hash
                            && url_withtout_hash !== current_url
                        )
                    ) {
                        a.addEventListener('click', (e) => {
                            // If Command (macOS) or Ctrl (Windows) key pressed, stop processing
                            if (e.metaKey || e.ctrlKey) {
                                // return w.open(element.src, '_blank');
                                return;
                            }

                            e.preventDefault()

                            html.classList.remove(CSS_CLASS.READY);
                            html.classList.remove(CSS_CLASS.LOADED);
                            // html.classList.add(CSS_CLASS.LEAVING);
                            html.classList.add(CSS_CLASS.LOADING);

                            this.onPageOut().then(() => {
                                rafTimeout(() => {
                                    window.location = url;
                                }, 400);
                            });
                        });
                    }
                }
            }
        });
    }

    bindAll() {
        [
            'handleRAF',
            'handleResize',
            // 'handleMouseDown',
            'handleMouseMove',
            // 'handleMouseUp',
            'handleScroll',
            // 'handleScrollEnd',
            // 'handleWheel',
            // 'handleWheelEnd',
            'onPageReady',
            'onPageLoaded',
            'onPageOut',
        ]
        .forEach(fn => this[fn] = this[fn].bind(this));
    }


    //--- Hooks

    handleRAF(time) {
        // console.log(time);
        this.lenis.raf(time);

        // for (let i = 0; i < this.components.length; i++) {
        //   const comp = this.components[i]

        //   if (typeof comp.render === 'function') {
        //     comp.render(time)
        //   }
        // }
        this.call('onRAF', time);

        requestAnimationFrame(this.handleRAF);
    }

    handleResize() {
        const windowDim = getWindowDimensions();
        this.bounds.width = windowDim.width;
        this.bounds.height = windowDim.height;
        this.bounds.headerH = this.DOM.header.offsetHeight;
        // this.scroll.treshold = ENV.IS_MOBILE ? this.bounds.width / 10 : this.bounds.width / 3;

        this.call('onResize', this.bounds);
    }

    // handleMouseDown = (e) => {
    //     const mousePos = getMousePos(e);
    //     this.call('onMouseDown', { e, mousePos });
    // }

    handleMouseMove(e) {
        const mousePos = getMousePos(e);
        this.call('onMouseMove', { e, mousePos });
    }

    // handleMouseUp = (e) => {
    //     const mousePos = getMousePos(e);
    //     this.call('onMouseUp', { e, mousePos });
    // }

    handleScroll({ scroll, direction, progress, limit }) { // { scroll, limit, velocity, direction, progress
        // console.log({ scroll, direction, progress, limit });

        this.scroll.target = scroll;
        this.scroll.progress = progress;
        this.scroll.direction = direction;

        // Scroll Direction
        if ( direction === 1 ) {
            html.classList.add(CSS_CLASS.SCROLL_DOWN);
            html.classList.remove(CSS_CLASS.SCROLL_UP);
        } else if ( direction === -1 ) {
            html.classList.add(CSS_CLASS.SCROLL_UP);
            html.classList.remove(CSS_CLASS.SCROLL_DOWN);
        }

        // Has Scroll ?
        // if ( this.scroll.target > this.bounds.appOffsetTop ) {
        if ( scroll > this.scroll.treshold ) {
            html.classList.add(CSS_CLASS.HAS_SCROLL);
        } else {
            html.classList.remove(CSS_CLASS.HAS_SCROLL);
        }

        this.call('onScroll', { scroll, progress, direction });
    }

    // handleScrollEnd() {
    //     this.call('onScrollEnd',);
    // }

    // handleWheel(e) {
    //     const y = e.deltaY;

    //     // TODO: test this
    //     // let y = e.wheelDeltaY || e.deltaY * -1; ???
    //     // if (isFF) { // modifier for FireFox
    //     //   y *= 5;
    //     // }

    //     this.call('onWheel', { y, e });
    // }

    // handleWheelEnd(e) {
    //     const y = e.deltaY;
    //     this.call('onWheelEnd');
    // }


    onPageReady() {
        // Start pageloader anim out
        html.classList.remove(CSS_CLASS.LOADING);
        html.classList.add(CSS_CLASS.READY);

        return this.call('onPageReady');
    }

    onPageLoaded() {
        html.classList.add(CSS_CLASS.LOADED);

        return this.call('onPageLoaded');
    }

    onPageOut() {
        return this.call('onPageOut');
    }


    //--- Helpers

    // getScrollY () {
    //   return window.scrollY || document.documentElement.scrollTop;
    // }

    // addModule(module) {
    //   addToArray(this.modules, module);
    // }

    // addPageModule(module) {
    //   addToArray(this.pageModules, module);
    //   addToArray(this.modules, module);
    // }

    call(func, vars = {}) {
        let components = [
            ...this.components,
            ...this.pageComponents
        ];
        if (func === 'onPageReady' || func === 'onPageLoaded' || func === 'onPageOut') {
            debug('call ' + func);
            let pr = [];
            components.forEach(comp => {
                if (typeof comp[func] === 'function') {
                    pr.push(comp[func](vars));
                }
            });
            return Promise.all(pr).then(() => {
                debug('all call ' + func + ' ended');
            });
        } else {
        components.forEach(comp => {
            if (typeof comp[func] === 'function') {
                comp[func](vars);
            }
        });
        }
    }

    copyright () {
        const style = [
            'color: #fff',
            'background: #ffc308',
            'padding: 4px 8px',
            'border-left: 5px solid #282828',
            'border-right: 5px solid #282828'
        ].join(';')

        console.log('%cA creation by Boite à Oeufs 🇫🇷', style)
        console.log('%cCopyright © 2024 • Tous droits réservés.', style)

        // console.log('%c> Design & Development by Boite à Oeufs', style)
        console.log('%c> Site : https://www.boite-a-oeufs.com', style)
        console.log('%c> Twitter : https://twitter.com/BoiteOeufs', style)
        console.log('%c> Facebook : https://www.facebook.com/boiteoeufs/', style)
    }
}


////////////////
// Init
////////////////

function init() {
    bindGlobalEvents();
    globals();
    setViewportSizes();

    // // Init the PageLoader
    // const pageloader = document.querySelector('.c-pageloader');
    // if (!sessionStorage.getItem('skipIntro')) {
    //   pageloader.classList.add('is-visible');
    // }

    // Eagerly load the following fonts.
    // if (isFontLoadingAPIAvailable) {
    //     loadFonts(FONT.EAGER, ENV.IS_DEV).then((eagerFonts) => {
    //     html.classList.add(CSS_CLASS.FONTS_LOADED);
    //     window.app = new App();

    //     // Debug fonts loading
    //     if (ENV.IS_DEV) {
    //         console.group('Eager fonts loaded!', eagerFonts.length, '/', document.fonts.size);
    //         console.group('State of eager fonts:');
    //         eagerFonts.forEach(font => console.log(font.family, font.style, font.weight, font.status));
    //         console.groupEnd();
    //         console.group('State of all fonts:');
    //         document.fonts.forEach(font => console.log(font.family, font.style, font.weight, font.status));
    //         console.groupEnd();
    //     }
    //     });
    // }
    // else {
        window.app = new App();
    // }

    /**
     * Debug focus
     */
    // if (ENV.IS_DEV) {
    //     document.addEventListener(
    //         "focusin",
    //         function () {
    //             console.log('focused: ', document.activeElement)
    //         }, true
    //     );
    // }


    // Register Service Worker
    // if ('serviceWorker' in navigator) {
    //    navigator.serviceWorker.register('/serviceworker.js');
    // }
    if ('serviceWorker' in navigator) {
        const scope = '/';

        // Delay registration until after the page has loaded, to ensure that our
        // precaching requests don't degrade the first visit experience.
        // See https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
        window.addEventListener('load', function () {
            // Your service-worker.js *must* be located at the top-level directory relative to your site.
            // It won't be able to control pages unless it's located at the same level or higher than them.
            // See https://github.com/slightlyoff/ServiceWorker/issues/468
            navigator.serviceWorker.register('/serviceworker.js', { scope: scope }).then(function(reg) {
                // debug('Service Worker registered successfully.')
            }).catch(function(e) {
                console.error('Error during service worker registration:', e)
            });
        });
    }
    // // Register Service Worker
    // if ('serviceWorker' in navigator) {
    //   const scope = '/'

    //   // Delay registration until after the page has loaded, to ensure that our
    //   // precaching requests don't degrade the first visit experience.
    //   // See https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
    //   // window.addEventListener('load', function () {

    //     // Your service-worker.js *must* be located at the top-level directory relative to your site.
    //     // It won't be able to control pages unless it's located at the same level or higher than them.
    //     // See https://github.com/slightlyoff/ServiceWorker/issues/468
    //     navigator.serviceWorker.register('/sw.js', { scope: scope }).then(function(reg) {
    //       // debug('Service Worker registered successfully.')
    //     }).catch(function(e) {
    //       console.error('Error during service worker registration:', e)
    //     })

    //   // })

    //   // // Detect if site launch via PWA
    //   // if (
    //   //   window.matchMedia('(display-mode: standalone)').matches ||
    //   //   window.navigator.standalone === true // for Safari
    //   // ) {
    //   //   debug('display-mode is standalone');
    //   // }
    //   // // // And same in CSS
    //   // // @media all and (display-mode: standalone) {
    //   // //   body {
    //   // //     background-color: yellow;
    //   // //   }
    //   // // }

    //   // let deferredPrompt;

    //   // window.addEventListener('beforeinstallprompt', (e) => {
    //   //   debug('test from beforeinstallprompt');

    //   //   // Stash the event so it can be triggered later.
    //   //   deferredPrompt = e;
    //   //   // Update UI notify the user they can add to home screen
    //   //   showInstallPromotion();
    //   //   // prompt();
    //   // });

    //   // window.addEventListener('appinstalled', (evt) => {
    //   //   debug('PWA installed');
    //   // });
    // }
}


////////////////
// Global events
////////////////

function bindGlobalEvents() {
    window.addEventListener('resize', onResize);
}

function onResize() {
    setViewportSizes()
}

function setViewportSizes() {

    // Document styles
    const documentStyles = html.style;

    // Viewport width
    const vw = document.body.clientWidth * 0.01;
    documentStyles.setProperty('--vw', `${vw}px`);

    // Return if browser supports vh, svh, dvh, & lvh
    // if (ENV.SUPPORTS_VH) {
    //   return
    // }

    // Viewport height
    const svh = html.clientHeight * 0.01;
    documentStyles.setProperty('--svh', `${svh}px`);

    const dvh = window.innerHeight * 0.01;
    documentStyles.setProperty('--dvh', `${dvh}px`);

    if (body) {
        const fixed = document.createElement('div');
        fixed.style.width = '1px';
        fixed.style.height = '100vh';
        fixed.style.position = 'fixed';
        fixed.style.left = '0';
        fixed.style.top = '0';
        fixed.style.bottom = '0';
        fixed.style.visibility = 'hidden';

        document.body.appendChild(fixed);

        var fixedHeight = fixed.clientHeight;

        fixed.remove();

        const lvh = fixedHeight * 0.01;

        documentStyles.setProperty('--lvh', `${lvh}px`);
    }
}


////////////////
// Execute
////////////////

function initialize() {
    debug('initialize app');

    init();

    // Run lazy script
    // new LazyScript();

    // // Get main CSS style
    // const $style = document.getElementById('main-css'); // critical-css

    // if ($style) {
    //     if ($style.isLoaded) {
    //         debug('main-css already loaded');
    //         init();
    //     } else {
    //         debug('wait until main-css is loaded');
    //         $style.addEventListener('load', init);
    //     }
    // } else {
    //     console.warn('The "main-css" stylesheet not found.');
    // }
}

// Launch init of the app

window.debug = debug;
window.fps = 30;

if (window._is_loaded) {
    debug('window already loaded');
    initialize();
} else {
    window.onload = () => {
        debug('on window loaded');
        initialize();
    };
}


// If the cache is loaded via bfcache (back/forward cache)
// https://web.dev/bfcache/
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        debug('This page was restored from the bfcache.');
        initialize();
    } else {
        // console.log('This page was loaded normally.');
    }
});

// Calculate FPS
const updateFpsEvent = new CustomEvent(CUSTOM_EVENTS.UPDATE_FPS, { 'detail': { 'fps': window.fps } });
calcFPS({count: 60, callback: (fps) => {
    window.fps = fps;
    updateFpsEvent.detail.fps = fps;
    window.dispatchEvent(updateFpsEvent);
}});

// window.onbeforeunload = function (e) {
//   // e = e || window.event;

//   // // For IE and Firefox prior to version 4
//   // if (e) {
//   //     e.returnValue = 'Sure?';
//   // }

//   // // For Safari
//   // return 'Sure?';

//   localStorage.clear();
// };
